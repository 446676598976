[
  {
    "names": [
      "goldstack-test-ssr--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.073b2023630972a561d6dad427f253a1.js"
  },
  {
    "names": [
      "goldstack-test-ssr--__index.map"
    ],
    "generatedName": "-__index.0a61b5130a2df743353738fee8359cd0.map.json"
  },
  {
    "names": [
      "goldstack-test-ssr-posts.map"
    ],
    "generatedName": "posts.a03541726cacc65c6542e13d7548027d.map.json"
  },
  {
    "names": [
      "goldstack-test-ssr-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.1402d289e3b7a7b42ab7d775ca4994e9.js"
  },
  {
    "names": [
      "goldstack-test-ssr-_greedy__.map"
    ],
    "generatedName": "_greedy__.d7f84bd112c7f475fe81f4ba17013d86.map.json"
  },
  {
    "names": [
      "goldstack-test-ssr-_greedy__-bundle.js"
    ],
    "generatedName": "_greedy__-bundle.87bae541feacf843c258c7404ce9d39c.js"
  }
]